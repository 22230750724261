<template>
  <div class="startFinishConfig">
    <StartFinishConfigDisplay
      :installation-id="installationId"
      :lane-number="laneNumber"
    />
  </div>
</template>

<script>
export default {
  name: 'StartFinishConfig',
  components: {
    StartFinishConfigDisplay: () => import('@/components/Config/StartFinish/StartFinishConfigDisplay.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    },   
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  metaInfo () {
    return {
      title: 'Start Finish Config'
    }
  }
}
</script>
